import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import BgImage from "../images/what-to-expect.jpg"
import Layout from "../components/layoutMainCustom"
import PageHeaders from "../components/pageHeaders"
import SEO from "../components/seo"
import ModalVideo from "react-modal-video"

const Links = styled(Link)`
  color: #312e2e;
  font-weight: 600;
`

const ParagraphText = styled.p`
  font-size: 1.25rem;
`

const HeaderText = styled.h1`
  margin-top: 2rem;
  font-size: 1.5rem;
`

const MainButton = styled(Link)`
  color: #fff;
  background: #f07525;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  font-family: Montserrat;
  font-weight: 600;
  border-radius: 8px;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-left: 1.5rem;
`

const SecondaryButton = styled.button`
  color: #fff;
  background: #312e2e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  font-family: Montserrat;
  font-weight: 600;
  border-radius: 8px;
  text-transform: uppercase;
  margin-left: 1.5rem;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
`

const IndexPage = () => (
  <Layout
    ImgSrc={BgImage}
    MainText="Who We Are"
    SecondText="Cross Church History"
  >
    <SEO title="Who We Are" />
    <PageHeaders
      css={css`
        display: flex;
      `}
    >
      Where We Started
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      Cross Church envisions itself being a place where people can come and hear
      the good news of Jesus for the first time. We recently changed our name
      from Palm Vista Baptist Church to Cross Church to better communicate this
      simple vision. Cross Church began in February, 1998 as Palm Vista Baptist
      Church. Meeting at Dysart High School the fledgling church faced an
      important strategic decision: should it focus on impacting the communities
      north of Bell Road or the communities south of it? Rather than choosing to
      focus exclusively on one or the other, the members of the church decided
      to reach out to both. So the church had a vision of people of all ages,
      races, and nationalities coming together to worship God, grow in their
      faith in Him, and make their faith known.
    </ParagraphText>
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 4rem;
      `}
    >
      A New Plan
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      When we moved into our current building in November 1999, the family
      communities that now lie to our south were yet to be constructed, so our
      early church enrollment was primarily senior adults, but things quickly
      changed. Surprise Farms became a flourishing family community, and our
      church collectively renewed its commitment to reach people at all stages
      of life. And now with the completion the Loop 303 our church is easily
      connected to communities both to the north and south of Surprise. Along
      the way we have added educational space for our church on the weekends and
      for use for our schools (Cross Christians School and Preschool) throughout
      the week.
      <br />
      <br />
      Most recently we constructed a new worship center on our Surprise Campus
      and moved into it in June 2015. This allowed us to remodel our original
      worship center into a children-friendly building that houses our Cross Church Kids. Cross
      Church has grown from those early days in the Dysart High School into a
      congregation of 1000 plus worshippers on Sunday. But more than that we
      have grown into a vision of being a church in the West Valley that is
      biblical, relevant, and missional. The{" "}
      <Links to="/phoenix">Phoenix Campus</Links> was launched in March of 2016
      as Cross Church’s second location and is the first campus to make us a
      multisite church.
    </ParagraphText>
    <PageHeaders
      css={css`
        display: flex;
        margin-top: 4rem;
      `}
    >
      Extending the Vision
    </PageHeaders>
    <HeaderText></HeaderText>
    <ParagraphText>
      Recently, God has inspired us to reach out in His name to our area
      schools, at-risk children in the foster care system, and mission causes
      here and around the world in an initiative we now call #WeCare. We invite
      you to consider coming alongside us as we write the next chapter in the
      Cross Church story!
    </ParagraphText>
  </Layout>
)

export default IndexPage
